.App {
  text-align: center;
  transition: 0.5s;
}
body{
  background:rgb(248, 251, 255);
}
.App-header {
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='2000' height='2000' viewBox='0 0 200 200'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='88' y1='88' x2='0' y2='0'%3E%3Cstop offset='0' stop-color='%23064e77'/%3E%3Cstop offset='1' stop-color='%230a7dbe'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='75' y1='76' x2='168' y2='160'%3E%3Cstop offset='0' stop-color='%238f8f8f'/%3E%3Cstop offset='0.09' stop-color='%23b3b3b3'/%3E%3Cstop offset='0.18' stop-color='%23c9c9c9'/%3E%3Cstop offset='0.31' stop-color='%23dbdbdb'/%3E%3Cstop offset='0.44' stop-color='%23e8e8e8'/%3E%3Cstop offset='0.59' stop-color='%23f2f2f2'/%3E%3Cstop offset='0.75' stop-color='%23fafafa'/%3E%3Cstop offset='1' stop-color='%23FFFFFF'/%3E%3C/linearGradient%3E%3Cfilter id='c' x='0' y='0' width='200%25' height='200%25'%3E%3CfeGaussianBlur in='SourceGraphic' stdDeviation='12' /%3E%3C/filter%3E%3C/defs%3E%3Cpolygon fill='url(%23a)' points='0 174 0 0 174 0'/%3E%3Cpath fill='%23000' fill-opacity='.5' filter='url(%23c)' d='M121.8 174C59.2 153.1 0 174 0 174s63.5-73.8 87-94c24.4-20.9 87-80 87-80S107.9 104.4 121.8 174z'/%3E%3Cpath fill='url(%23b)' d='M142.7 142.7C59.2 142.7 0 174 0 174s42-66.3 74.9-99.3S174 0 174 0S142.7 62.6 142.7 142.7z'/%3E%3C/svg%3E");
background-attachment: fixed;
background-repeat: no-repeat;
/* background-position: top left; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  
}

.headerContainer {
margin: 4em;
}
@media only screen and (min-width: 800px) {
  .header h1{

    font-size: 80px;
  }
  
  .quote {
  max-width: 500px;
  font-weight: 500;
  
   
  }
}
.header {
  opacity: 0; /* Set initial opacity to 0 */
  animation: fadeInUp 2s forwards; /* Apply the animation */
}

@keyframes fadeInUp {
  from {
    opacity: 0; /* Start from an opacity of 0 */
    transform: translateY(20px); /* Move up by 20 pixels */
  }
  to {
    opacity: 1; /* Fade in to full opacity */
    transform: translateY(0); /* Return to the original position */
  }
}

.quote {
  opacity: 1; /* Set initial opacity to 0 */
  animation: fadeInFromLeft 2s ease-in; /* Apply the animation */
}

@keyframes fadeInFromLeft {
  from {
    opacity: 0; /* Start from an opacity of 0 */
    transform: translateY(20px); /* Move up by 20 pixels */
  }
  to {
    opacity: 1; /* Fade in to full opacity */
    transform: translateY(0); /* Return to the original position */
  }
}

.btn-book-now{
background: linear-gradient(180deg, rgb(252, 94, 168) 0%, rgb(247, 104, 104) 50%, rgb(255, 161, 161) 100%);
  border: none;
  border-radius: 2px;
  padding: 20px;
  max-width: 500px;

  color: whitesmoke;
  font-weight: bold;
  
}

.footer {
  /* position: fixed; */
  font-size: 10px;
  top: 100%;
  
  background:rgb(248, 251, 255);
  border-radius: 5px 5px 0 0;
  width: 100%;
  /* height: 1000px; */
  padding: 30px;
  text-align: left;
  color: #1f2530;

 
}
.sub-heading {
  color: #61dafb;
}
.nav-banner{
position: fixed;
background: rgb(0, 0, 0);
/* opacity: 0.9; */
/* bottom: 94%; */
width: 100%;
font-weight: bold;
/* border-radius: 2px; */


}
.nav{
  align-items: center;
  justify-content: right;
}
.nav-link {
  color: azure;
  box-shadow: 2px red;
}
.nav-item :hover  {
  color: rgb(0, 79, 113);
}

a{
  text-decoration: none;
  list-style-type: none;
  
}

.contact{
  background: #dff8ff;
  width: 100%;
  height: 1000px;
  color: #61dafb;
}

.aboutMe{
  padding: 60px;
  text-align: justify;
  margin-top: 40px;
  font-size: 20px;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.1);
/* border-radius: 10px; */
  background: white;
  /* opacity: 0.9; */
  color: #1f2530;
}
.aboutMe p {
  display: flex;
  flex-direction: row;

}
.profilePic{
  margin-left: 40px;
  width: 300px;
  border-radius: 20%;
}
.section {
 
  padding: 40px;
  /* background: #1f2530; */

}
.reviews {
  /* background: blue; */
  /* position: fixed; */
  top: 80%;
  align-items: center;
  color: #1f2530;
}

/* form  */

.App-header {
  display: flex;
  justify-content: center;
  align-items: center;
  /* min-height: 100vh; */
}
/* Style the form container */
.bookingForm {
  margin-top: 2em;
  background-color: #fff;
  padding: 24px;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  width: 700px;
  color: black;
  font-size:x-small;
}

.bookingForm h2{

  display: flex;
  justify-content: center;
  align-items: center;
}

/* Style form inputs and labels */
.bookingForm label {
  font-weight: bold;
  margin-bottom: 8px;
  display: block;
}

.bookingForm select,
.bookingForm textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  color: black;
}

/* Highlight input on focus */
.bookingForm select:focus,
.bookingForm textarea:focus {
  border-color: #1890ff;
}

/* Style the submit button */
.bookingForm button {
  width: 100%;
  padding: 12px;
  margin-top: 10px;
  background-color: #1890ff;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

/* Change button color on hover */
.bookingForm button:hover {
  background-color: #096dd9;
}
.alertSuccess {
  margin: 20px;
flex-direction: row;
  background: rgb(0, 204, 0);
  border-radius: 5px;
  animation: fadeInUp 1s forwards; /* Apply the animation */
}